import {
  Box,
  capitalize,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  type SelectProps,
} from '@mui/material';
import {
  NotificationStatus,
  ResourceType,
  type BackupJob,
  type RestoreJob,
  type AuditLog,
  type BackupPolicy,
  type FileSearchRecord,
  type InventoryResource,
  type Notification,
  JobStatus,
} from '@repo/api-gw-sdk';
import { BackupStatus, Provider, DataClass } from '@repo/api-gw-sdk';
import type { Row } from '@tanstack/react-table';
import { type StaticImageData } from 'next/image';
import React from 'react';

import { identifyDelimiter } from '@/app/(dashboard)/search/searchUtils';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';
import {
  ApplicationsLogos,
  ApplicationsLogosWithAliases,
  BackupStatuses,
  CloudProviders,
  DatabaseEngineAliases,
  DataClasses,
  Environments,
  JobStatuses,
  ResourceTypes,
} from '@/data/inventory/data';
import { formatRegionName } from '@/data/inventory/regions';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import {
  PropertyPolicyRelation,
  PropertyType,
  StringOperator,
  type FilterProperty,
} from '@/types/advanceFilter';
import type { SamlGroup } from '@/types/groups';
import { isDateValid } from '@/utils/dateTime';
import { DATE_TIME_FORMAT, dayjs } from '@/utils/dayjs';
import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import {
  AppsFilter,
  BackupStatusFilter,
  FreeTextFilter,
  MultiSelectFilter,
  TagsFilter,
} from './filters';

import BackupPolicyTag, {
  BackupPolicyTagFromId,
} from '../backupPolicy/BackupPolicyTag';
import { BackupStatusIcon } from '../backupStatus/backupStatusIcon';
import { ControlsColumnIndicators } from '../controls/controlIndicator';
import type { FileExplorerTableItem } from '../explorer/fileExplorerPage';
import {
  NotificationStatuses,
  NotificationTypes,
  supportedNotificationTypes,
} from '../notifications/data';
import { CircleImage } from '../shared/circleImage';
import { Tag } from '../tag';
import { TagsElasticContainer } from '../tags/tagsElasticContainer';
import VaultTag from '../vaults/vaultTag';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isJob = (entity: any): entity is BackupJob | RestoreJob =>
  'jobExecutionDetails' in entity;

const PropertySelection = (
  props: SelectProps & {
    showImagesInRender?: boolean;
    allowAddingOption?: boolean;
    options: {
      value: string;
      label: React.ReactNode;
      image?: StaticImageData;
    }[];
  }
) => {
  const { options, showImagesInRender, allowAddingOption, ...rest } = props;

  return (
    <Select
      {...rest}
      displayEmpty
      renderValue={(selected) => {
        const opts = (Array.isArray(selected) ? selected : [selected])
          .map((opt) => options.find((o) => o.value === opt)!)
          .filter(Boolean);

        if (opts.length === 0) {
          return (
            <Typography
              sx={{ alignSelf: 'center' }}
              color='var(--mui-palette-text-disabled)'
            >
              Select
            </Typography>
          );
        }

        return opts.map((option, i) =>
          showImagesInRender && option.image ? (
            <Tooltip key={option.value} title={option.label as string}>
              <>
                <CircleImage
                  className='mr-[8px]'
                  style={{ display: 'inline' }}
                  alt={option.value}
                  src={option.image}
                  height={30}
                />
              </>
            </Tooltip>
          ) : (
            <React.Fragment key={option.value}>
              {option.label}
              {i < opts.length - 1 ? ', ' : null}
            </React.Fragment>
          )
        );
      }}
    >
      {options.map((option) => {
        const isChecked = (props.value as string[]).includes(option.value);
        return (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && (
              <Checkbox className='mr-[8px]' checked={isChecked} />
            )}
            {option.image && (
              <>
                <CircleImage
                  contrast={isChecked}
                  alt={option.value}
                  src={option.image}
                  className='mr-[8px]'
                  height={30}
                />
              </>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const id: FilterProperty<InventoryResource> = {
  name: 'id',
  displayName: '',
  type: PropertyType.String,
  group: 'primary',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
};

export const cloudProvider: FilterProperty<InventoryResource> = {
  name: 'cloudProvider',
  displayName: 'Cloud Provider',
  type: PropertyType.String,
  group: 'primary',
  propertyPredicate: (providers) => providers.size > 1,
  column: {
    enableHiding: false,
    enableSorting: false,
    cell: ({ row }) =>
      CloudProviders[row.original.cloudProvider] && (
        <Box className='flex items-center'>
          <CircleImage
            key={row.original.cloudProvider}
            alt={CloudProviders[row.original.cloudProvider].displayName}
            src={CloudProviders[row.original.cloudProvider].logo}
          />
        </Box>
      ),
  },
  filter: {
    Component: (props) => {
      const { sourceAccounts } = useUser();
      const providers = Array.from(
        new Set((sourceAccounts || []).map((x) => x.cloudProvider))
      );

      const options = providers
        .map((provider) => ({
          id: provider,
          name: CloudProviders[provider].displayName,
          image: CloudProviders[provider].logo,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      return (
        <MultiSelectFilter
          {...props}
          options={options.map((x) => ({
            id: x.id,
            name: x.name,
            Image: (
              <CircleImage alt={x.name} src={x.image} className='mr-[8px]' />
            ),
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      return CloudProviders[conditionValue]?.displayName || conditionValue;
    },
  },
};

export const instanceId: FilterProperty<FileSearchRecord> = {
  name: 'instanceId',
  displayName: 'Resource ID',
  type: PropertyType.String,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.instanceId && (
        <Typography>{row.original.instanceId}</Typography>
      ),
  },
  field: {
    value: (entity) => {
      if (!('instanceId' in entity)) {
        return undefined;
      }
      return <Typography>{entity.instanceId}</Typography>;
    },
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const resourceId: FilterProperty<
  InventoryResource | FileSearchRecord | BackupJob | RestoreJob
> = {
  name: 'resourceId',
  displayName: 'Resource ID',
  type: PropertyType.String,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) => {
      const resourceId = isJob(row.original)
        ? row.original.jobExecutionDetails.resourceDetails?.providerResourceId
        : 'providerResourceId' in row.original
          ? row.original.providerResourceId
          : row.original.resourceId;

      if (!resourceId) {
        return null;
      }

      const parts = resourceId.split('/');
      if (parts.length === 1) {
        return <Typography>{resourceId}</Typography>;
      }

      return (
        <Tooltip title={resourceId}>
          <Typography>{parts.slice(parts.length - 2).join('/')}</Typography>
        </Tooltip>
      );
    },
  },
  field: {
    value: (entity) => {
      if ('type' in entity || isJob(entity)) {
        return undefined;
      }

      const parts = entity.providerResourceId.split('/');
      if (parts.length === 1) {
        return <Typography>{entity.providerResourceId}</Typography>;
      }

      return (
        <Tooltip title={entity.providerResourceId}>
          <Typography>{parts.slice(parts.length - 2).join('/')}</Typography>
        </Tooltip>
      );
    },
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

const AccountIdCloudProviderMapping: Record<string, string> = {
  Azure: 'Subscription',
  GCP: 'Project',
};

export const accountId: FilterProperty<InventoryResource> = {
  name: 'accountId',
  displayName: 'Account',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }: { row: Row<InventoryResource> }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dal = useDAL();
      const { body } = dal.cloudAccounts.source.list();

      return (
        <Typography>
          {body?.accounts.find(
            (a) => a.providerAccountId === row.original.accountId
          )?.name || row.original.accountId}
        </Typography>
      );
    },
  },
  field: {
    titleKey: (entity) =>
      AccountIdCloudProviderMapping[entity.cloudProvider] || 'Account',
    value: (entity) =>
      entity.accountId && <Typography>{entity.accountId}</Typography>,
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.source.list();

      const options =
        body?.accounts.map((x) => ({
          id: x.providerAccountId,
          name: x.name || x.providerAccountId,
          image: CloudProviders[x.cloudProvider]?.logo,
          cloudProvider: CloudProviders[x.cloudProvider],
        })) || [];

      return (
        <MultiSelectFilter
          {...props}
          options={
            options
              .sort(
                (a, b) =>
                  (a.cloudProvider?.displayName || '').localeCompare(
                    b.cloudProvider?.displayName || ''
                  ) || a.name.localeCompare(b.name)
              )
              .map((x) => ({
                id: x.id,
                name: x.name,
                Image: x.image && (
                  <CircleImage
                    alt={x.name}
                    src={x.image}
                    className='mr-[8px]'
                  />
                ),
              })) || []
          }
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body, isLoading } = dal.cloudAccounts.source.list();

      if (isLoading) {
        return '...';
      }

      return (
        body?.accounts.find((x) => x.providerAccountId === conditionValue)
          ?.name || conditionValue
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.source.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.accounts.map(({ providerAccountId, name }) => ({
              value: providerAccountId,
              label: name || providerAccountId,
            })) || []
          }
        />
      );
    },
  },
};

export const resourceName: FilterProperty<InventoryResource | BackupJob> = {
  name: 'resourceName',
  displayName: 'Name',
  type: PropertyType.String,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.String,
  column: {
    enableHiding: false,
    cell: ({ row }) => {
      if (isJob(row.original)) {
        return (
          row.original.jobExecutionDetails.resourceDetails?.resourceName && (
            <Typography>
              {row.original.jobExecutionDetails.resourceDetails.resourceName}
            </Typography>
          )
        );
      }

      return (
        row.original.resourceName && (
          <Typography>{row.original.resourceName}</Typography>
        )
      );
    },
  },
  field: {
    value: (entity) =>
      !isJob(entity) &&
      entity.resourceName && <Typography>{entity.resourceName}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by name'} />
    ),
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const dateDiscovered: FilterProperty<InventoryResource> = {
  name: 'dateDiscovered',
  displayName: 'First discovered (UTC)',
  type: PropertyType.Date,
  group: 'backup-details',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.dateDiscoveredTime) ? (
        <Typography>
          {dayjs.utc(row.original.dateDiscoveredTime).format(DATE_TIME_FORMAT)}
        </Typography>
      ) : undefined,
  },
  field: {
    value: (entity) =>
      isDateValid(entity.dateDiscoveredTime) ? (
        <Typography>
          {dayjs.utc(entity.dateDiscoveredTime).format(DATE_TIME_FORMAT)}
        </Typography>
      ) : undefined,
  },
};

export const createdTime: FilterProperty<InventoryResource> = {
  name: 'createdTime',
  displayName: 'Created time (UTC)',
  type: PropertyType.Date,
  group: 'source-location',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.createdTime) ? (
        <Typography>
          {dayjs.utc(row.original.createdTime).format(DATE_TIME_FORMAT)}
        </Typography>
      ) : undefined,
  },
  field: {
    value: (entity) =>
      isDateValid(entity.createdTime) ? (
        <Typography>
          {dayjs.utc(entity.createdTime).format(DATE_TIME_FORMAT)}
        </Typography>
      ) : undefined,
  },
};

export const vaults: FilterProperty<InventoryResource> = {
  name: 'vaults',
  displayName: 'Vaults',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    cell: ({ row }) => {
      if (!row.original?.vaults?.length) return;
      const uniqueIds = Array.from(
        new Set(row.original.vaults.map((x) => x.id))
      );
      return uniqueIds.map((id) => <VaultTag key={id} vault={id} />);
    },
  },
  field: {
    value: (entity) => {
      if (!entity.vaults?.length) return;
      const uniqueIds = Array.from(new Set(entity.vaults.map((x) => x.id)));
      return uniqueIds.map((id) => <VaultTag key={id} vault={id} />);
    },
  },
  filter: {
    Component: (props) => {
      const { data } = useBackupVaults();

      return (
        <MultiSelectFilter
          {...props}
          options={(data || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <VaultTag vault={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const { data } = useBackupVaults();

      return data.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const resourceType: FilterProperty<InventoryResource | BackupJob> = {
  name: 'resourceType',
  displayName: 'Resource type',
  type: PropertyType.SingleValue,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    enableSorting: false,
    cell: ({ row }) => {
      const resourceType = isJob(row.original)
        ? row.original.jobExecutionDetails.resourceDetails?.resourceType
        : row.original.resourceType;

      if (!resourceType) {
        return null;
      }

      const cloudProvider = ResourceTypes[resourceType]?.provider;

      return (
        resourceType && (
          <Box className='flex items-center'>
            {CloudProviders[cloudProvider] && (
              <CircleImage
                key={cloudProvider}
                alt={CloudProviders[cloudProvider].displayName}
                src={CloudProviders[cloudProvider].logo}
                className='mr-[8px]'
              />
            )}
            <Typography>{ResourceTypes[resourceType]?.displayName}</Typography>
          </Box>
        )
      );
    },
  },
  field: {
    value: (entity) => {
      if (isJob(entity) || !entity.resourceType) {
        return null;
      }

      const cloudProvider = ResourceTypes[entity.resourceType]?.provider;

      return (
        <Box className='flex items-center p-0' sx={{ height: '18px' }}>
          {CloudProviders[cloudProvider] && (
            <CircleImage
              alt={CloudProviders[cloudProvider].displayName}
              src={CloudProviders[cloudProvider].logo}
              sx={{ height: '24px', width: '24px' }}
              className='mr-[8px]'
            />
          )}
          <Typography>
            {ResourceTypes[entity.resourceType]?.displayName}
          </Typography>
        </Box>
      );
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceTypes.list();

      const options =
        body?.resourceTypes.map((x) => ({
          id: x,
          name: ResourceTypes[x]?.displayName,
          image: CloudProviders[ResourceTypes[x]?.provider]?.logo,
          cloudProvider: CloudProviders[ResourceTypes[x]?.provider],
        })) || [];

      return (
        <MultiSelectFilter
          {...props}
          options={
            options
              .sort(
                (a, b) =>
                  (a.cloudProvider?.displayName || '').localeCompare(
                    b.cloudProvider?.displayName || ''
                  ) || a.name.localeCompare(b.name)
              )
              .map((x) => ({
                id: x.id,
                name: x.name,
                Image: x.image && (
                  <CircleImage
                    alt={x.name}
                    src={x.image}
                    className='mr-[8px]'
                  />
                ),
              })) || []
          }
        />
      );
    },
    Badge: ({ conditionValue }) => {
      return ResourceTypes[conditionValue]?.displayName;
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceTypes.list();

      return (
        <PropertySelection
          {...props}
          options={
            body?.resourceTypes.map((x) => ({
              value: x,
              label: ResourceTypes[x]?.displayName,
              image: CloudProviders[ResourceTypes[x]?.provider]?.logo,
            })) || []
          }
        />
      );
    },
  },
};

export const resourceGroupName: FilterProperty<InventoryResource> = {
  name: 'resourceGroupName',
  displayName: 'Resource group',
  type: PropertyType.String,
  group: 'source-location',
  propertyPredicate: (providers) => providers.has(Provider.Azure),
  column: {
    enableHiding: true,
    enableColumnFilter: true,
    enableSorting: true,
    cell: ({ row }) =>
      row.original.resourceGroupName && (
        <Typography>{row.original.resourceGroupName}</Typography>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceGroupName && (
        <Typography>{entity.resourceGroupName}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceGroups.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.resourceGroups || []).map((rg) => ({
            id: rg,
            name: rg,
          }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceGroups.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.resourceGroups.map((rg) => ({
              value: rg,
              label: rg,
            })) || []
          }
        />
      );
    },
  },
};

export const apps: FilterProperty<InventoryResource | FileSearchRecord> = {
  name: 'apps',
  displayName: 'Apps',
  type: PropertyType.MultipleValues,
  group: 'classifications',
  policyRelation: PropertyPolicyRelation.MultipleValues,
  column: {
    enableSorting: false,
    cell: ({ row }) => {
      const apps =
        ('apps' in row.original
          ? row.original.apps
          : 'classifications' in row.original
            ? row.original.classifications?.appsDetails?.apps?.map(
                (x) => x.name
              )
            : undefined) || [];

      return (
        !!apps.length &&
        apps.map((name) => (
          <Tooltip
            key={name}
            title={
              <Typography
                variant='body2'
                component='span'
                className='text-inherit'
              >
                {name}
              </Typography>
            }
          >
            <CircleImage
              key={name}
              alt={name}
              src={ApplicationsLogosWithAliases[name]}
            />
          </Tooltip>
        ))
      );
    },
  },
  field: {
    value: (entity) => {
      const apps =
        ('apps' in entity
          ? entity.apps
          : 'classifications' in entity
            ? entity.classifications?.appsDetails?.apps?.map((x) => x.name)
            : undefined) || [];

      return (
        !!apps.length &&
        apps.map((name) => (
          <div key={name} className='flex items-center'>
            <CircleImage
              alt={name}
              src={ApplicationsLogosWithAliases[name]}
              className='mr-[8px]'
            />
            <Typography>{name}</Typography>
          </div>
        ))
      );
    },
  },
  filter: {
    openFullSize: {
      title: 'Apps',
      subtitle: 'To filter your resources, select one or more apps',
    },
    Component: AppsFilter,
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.apps.list();
      const opts = Array.from(
        new Set([
          ...(body?.apps ?? []),
          ...Object.keys(ApplicationsLogos).filter((x) => x !== 'Unknown'),
        ])
      );
      return (
        <PropertySelection
          {...props}
          showImagesInRender={true}
          options={
            opts.map((value) => ({
              value,
              label: value,
              image: ApplicationsLogosWithAliases[value],
            })) || []
          }
        />
      );
    },
  },
};

export const dataClasses: FilterProperty<InventoryResource> = {
  name: 'dataClasses',
  displayName: 'Data classes',
  type: PropertyType.MultipleValues,
  policyRelation: PropertyPolicyRelation.MultipleValues,
  group: 'classifications',
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.classifications?.dataClassesDetails?.dataClasses
        ?.length && (
        <Stack gap='8px' direction='row'>
          {row.original.classifications.dataClassesDetails.dataClasses.map(
            (x) => (
              <Tooltip key={x} title={DataClasses[x]?.title}>
                <Tag variant='filled' content={x} />
              </Tooltip>
            )
          )}
        </Stack>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.classifications?.dataClassesDetails?.dataClasses?.length && (
        <Stack gap='8px' direction='row'>
          {entity.classifications.dataClassesDetails.dataClasses.map((x) => (
            <Tooltip key={x} title={DataClasses[x]?.title}>
              <Tag variant='filled' content={x} />
            </Tooltip>
          ))}
        </Stack>
      ),
  },
  filter: {
    Component: (props) => (
      <MultiSelectFilter
        {...props}
        options={Object.values(DataClass)
          .filter((x) => x !== DataClass.DataClassUnspecified)
          .map((id) => ({
            id,
            name: '',
            Image: <Tag variant='filled' content={id} />,
            description: DataClasses[id]?.description,
          }))}
      />
    ),
  },
  dropdown: {
    Component: (props) => (
      <PropertySelection
        {...props}
        options={
          Object.entries(DataClass)
            .filter((x) => x[1] !== DataClass.DataClassUnspecified)
            .map(([, label]) => ({ value: label, label })) || []
        }
      />
    ),
  },
};

export const tags: FilterProperty<InventoryResource> = {
  name: 'tags',
  displayName: 'Tags',
  type: PropertyType.MultipleValues,
  policyRelation: PropertyPolicyRelation.Tags,
  group: 'primary',
  column: {
    enableSorting: false,
    cell: ({ row }) => <TagsElasticContainer tags={row.original.tags} />,
  },
  field: {
    value: (entity) =>
      !!Object.entries(entity.tags || {}).length && (
        <Stack gap='8px' direction='row' flexWrap='wrap'>
          {Object.entries(entity.tags).map((entry) => (
            <Tag
              variant='outlined'
              key={entry[0]}
              content={entry.filter((x) => x).join('=')}
            />
          ))}
        </Stack>
      ),
  },
  filter: {
    openFullSize: {
      title: 'Tags',
    },
    Component: TagsFilter,
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const environment: FilterProperty<InventoryResource | FileSearchRecord> =
  {
    name: 'environment',
    displayName: 'Environment',
    type: PropertyType.SingleValue,
    group: 'classifications',
    policyRelation: PropertyPolicyRelation.SingleValue,
    column: {
      cell: ({ row }) => {
        const environment =
          ('environment' in row.original
            ? row.original.environment
            : 'classifications' in row.original
              ? row.original.classifications?.environmentDetails?.environment
              : undefined) || '';

        return (
          environment &&
          Environments[environment] && (
            <Typography>{Environments[environment].title}</Typography>
          )
        );
      },
    },
    field: {
      value: (entity) => {
        const environment =
          ('environment' in entity
            ? entity.environment
            : 'classifications' in entity
              ? entity.classifications?.environmentDetails?.environment
              : undefined) || '';

        return (
          environment &&
          Environments[environment] && (
            <Typography>{Environments[environment].title}</Typography>
          )
        );
      },
    },
    filter: {
      Component: (props) => {
        const dal = useDAL();
        const { body } = dal.inventory.environments.list();

        return (
          <MultiSelectFilter
            {...props}
            options={(body?.environments || []).map((x) => ({
              id: x,
              name: Environments[x]?.title,
              description: Environments[x]?.description,
            }))}
          />
        );
      },

      Badge: ({ conditionValue }) => {
        return Environments[conditionValue]?.title;
      },
    },
    dropdown: {
      Component: (props) => {
        const dal = useDAL();
        const { body } = dal.inventory.environments.list();

        return (
          <PropertySelection
            {...props}
            options={
              body?.environments.map((x) => ({
                value: x,
                label: Environments[x]?.title,
              })) || []
            }
          />
        );
      },
    },
  };

const getBackupPolicesTags = (backupPolicies: BackupPolicy[]) =>
  !!backupPolicies.length &&
  backupPolicies
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((policy) => <BackupPolicyTag key={policy.id} policy={policy} />);

export const backupPolicies: FilterProperty<InventoryResource> = {
  name: 'backupPolicies',
  displayName: 'Active backup policies',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) => getBackupPolicesTags(row.original.backupPolicies),
  },
  field: {
    value: (entity) => getBackupPolicesTags(entity.backupPolicies),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.backupPolicies || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <BackupPolicyTag policy={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        body?.backupPolicies.find((x) => x.id === conditionValue)?.name || '...'
      );
    },
  },
};

const VolumesCloudProviderMapping: Record<string, string> = {
  Azure: 'Disks',
  GCP: 'Disks',
};

export const sourceStorageSize: FilterProperty<InventoryResource | BackupJob> =
  {
    name: 'sourceStorageSize',
    displayName: 'Source storage size',
    type: PropertyType.String,
    group: 'backup-details',
    column: {
      enableColumnFilter: false,
      cell: ({ row }) => {
        if (isJob(row.original)) {
          if (
            row.original.jobExecutionDetails.resourceDetails
              ?.sourceStorageSizeBytes
          ) {
            return (
              <Typography>
                {fileSizeFormatter(
                  row.original.jobExecutionDetails.resourceDetails
                    .sourceStorageSizeBytes
                )}
              </Typography>
            );
          }

          return null;
        }

        if (
          row.original.backupStatus === BackupStatus.InitialClassification &&
          (!row.original.sourceStorage?.sizeBytes ||
            row.original.sourceStorage.sizeBytes === 0)
        ) {
          return <></>;
        }

        const files = row.original.sourceStorage.filesCount;
        const volumes = row.original.sourceStorage.volumeCount;

        return (
          <Tooltip
            title={
              !!(files || volumes) && (
                <Typography
                  variant='body2'
                  component='span'
                  className='text-inherit'
                >
                  {!!files && <div>{`# Files: ${files}`}</div>}
                  {!!volumes && (
                    <div>
                      {`# ${VolumesCloudProviderMapping[row.original.cloudProvider] || 'Volumes'}: ${volumes}`}
                    </div>
                  )}
                </Typography>
              )
            }
          >
            <Typography>
              {fileSizeFormatter(
                row.original.sourceStorage.sizeBytes,
                1,
                false
              )}
            </Typography>
          </Tooltip>
        );
      },
    },
    field: {
      value: (entity) => {
        if (isJob(entity)) {
          return null;
        }

        if (
          entity.sourceStorage.sizeBytes ||
          (entity.resourceType === ResourceType.AwsS3 &&
            entity.backupStatus !== BackupStatus.InitialClassification)
        ) {
          return (
            <Typography>
              {fileSizeFormatter(entity.sourceStorage.sizeBytes, 1, false)}
            </Typography>
          );
        }
        return null;
      },
    },
  };

export const backupStorageSize: FilterProperty<InventoryResource> = {
  name: 'backupStorageSize',
  displayName: 'Eon snapshot size',
  type: PropertyType.String,
  group: 'backup-details',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) =>
      !!row.original.snapshotStorage?.sizeBytes && (
        <Typography>
          {fileSizeFormatter(row.original.snapshotStorage.sizeBytes)}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.snapshotStorage?.sizeBytes && (
        <Typography>
          {fileSizeFormatter(entity.snapshotStorage.sizeBytes)}
        </Typography>
      ),
  },
};

export const genericSnapshotsDistinctDateCount: FilterProperty<InventoryResource> =
  {
    name: 'genericSnapshotsDistinctDateCount',
    displayName: 'Non-Eon snapshot count',
    type: PropertyType.String,
    group: 'backup-details',
    column: {
      enableColumnFilter: false,
      cell: ({ row }) =>
        !!row.original.snapshotStorage?.otherSnapshotCount && (
          <Typography>
            {row.original.snapshotStorage.otherSnapshotCount}
          </Typography>
        ),
    },
    field: {
      value: (entity) =>
        !!entity.snapshotStorage?.otherSnapshotCount && (
          <Typography>{entity.snapshotStorage.otherSnapshotCount}</Typography>
        ),
    },
  };

const VpcCloudProviderMapping: Record<string, string> = {
  Azure: 'Virtual network',
};

export const vpc: FilterProperty<InventoryResource> = {
  name: 'vpc',
  displayName: 'VPC',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  propertyPredicate: (providers) => providers.has(Provider.Aws),
  column: {
    cell: ({ row }) =>
      row.original.resourceProperties?.vpc && (
        <Typography>{row.original.resourceProperties.vpc}</Typography>
      ),
  },
  field: {
    titleKey: (entity) =>
      VpcCloudProviderMapping[entity.cloudProvider] || 'VPC',
    value: (entity) =>
      entity.resourceProperties?.vpc && (
        <Typography>{entity.resourceProperties.vpc}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.networks.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.networks || []).map((x) => ({ id: x, name: x }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.networks.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.networks.map((value) => ({ value, label: value })) || []
          }
        />
      );
    },
  },
};

export const subnets: FilterProperty<InventoryResource> = {
  name: 'subnets',
  displayName: 'Subnets',
  type: PropertyType.MultipleValues,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.MultipleValues,
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.resourceProperties?.subnets?.length && (
        <Typography>
          {row.original.resourceProperties.subnets.join(', ')}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.resourceProperties?.subnets?.length && (
        <Typography>{entity.resourceProperties.subnets.join(', ')}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.subnets.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.subnets || []).map((x) => ({ id: x, name: x }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.subnets.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.subnets.map((value) => ({ value, label: value })) || []
          }
        />
      );
    },
  },
};

export const lastBackup: FilterProperty<InventoryResource | FileSearchRecord> =
  {
    name: 'lastBackup',
    displayName: 'Last backup (UTC)',
    type: PropertyType.Date,
    group: 'backup-details',
    column: {
      enableColumnFilter: false,
      cell: ({ row }) => {
        const lastBackup =
          'lastBackup' in row.original && row.original.lastBackup;
        if (isDateValid(lastBackup)) {
          return (
            <Typography>
              {dayjs.utc(lastBackup).format(DATE_TIME_FORMAT)}
            </Typography>
          );
        }

        const generatedOn =
          'snapshots' in row.original &&
          row.original.snapshots?.length &&
          row.original.snapshots[0].generatedOn;

        if (isDateValid(generatedOn)) {
          return (
            <Typography>
              {dayjs.utc(generatedOn).format(DATE_TIME_FORMAT)}
            </Typography>
          );
        }
      },
    },
    field: {
      value: (entity) => {
        const lastBackup = 'lastBackup' in entity && entity.lastBackup;
        if (isDateValid(lastBackup)) {
          return (
            <Typography>
              {dayjs.utc(lastBackup).format(DATE_TIME_FORMAT)}
            </Typography>
          );
        }

        const generatedOn =
          'snapshots' in entity &&
          entity.snapshots?.length &&
          entity.snapshots[0].generatedOn;

        if (isDateValid(generatedOn)) {
          return (
            <Typography>
              {dayjs.utc(generatedOn).format(DATE_TIME_FORMAT)}
            </Typography>
          );
        }
      },
    },
  };

export const sourceRegion: FilterProperty<InventoryResource> = {
  name: 'sourceRegion',
  displayName: 'Source region',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.resourceProperties?.sourceRegion && (
        <Typography>
          {formatRegionName(row.original.resourceProperties.sourceRegion)}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceProperties?.sourceRegion && (
        <Typography>
          {formatRegionName(entity.resourceProperties.sourceRegion)}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.regions.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.regions || [])
            .map((x) => ({ id: x.name, name: formatRegionName(x.name) }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      );
    },
    Badge({ conditionValue }) {
      return formatRegionName(conditionValue) || conditionValue;
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.regions.list();
      return (
        <PropertySelection
          {...props}
          options={
            body?.regions.map((value) => ({
              value: value.name,
              label: formatRegionName(value.name),
            })) || []
          }
        />
      );
    },
  },
};

export const backupRegions: FilterProperty<InventoryResource> = {
  name: 'backupRegions',
  displayName: 'Backup regions',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.vaults.length && (
        <Typography>
          {Array.from(
            new Set(row.original.vaults.map((x) => formatRegionName(x.region)))
          )
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.vaults.length && (
        <Typography>
          {Array.from(
            new Set(entity.vaults.map((x) => formatRegionName(x.region)))
          )
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.backupRegions.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.regions || [])
            .map((x) => ({ id: x.name, name: formatRegionName(x.name) }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      );
    },
    Badge({ conditionValue }) {
      return formatRegionName(conditionValue) || conditionValue;
    },
  },
};

export const backupRegion: FilterProperty<BackupJob> = {
  name: 'backupRegion',
  displayName: 'Backup region',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const vaults = useBackupVaults();
      const vault = vaults.data.find(
        (x) => x.id === row.original.jobExecutionDetails.vaultId
      );
      return formatRegionName(vault?.region);
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.backupRegions.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.regions || [])
            .map((x) => ({ id: x.name, name: formatRegionName(x.name) }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      );
    },
    Badge({ conditionValue }) {
      return formatRegionName(conditionValue) || conditionValue;
    },
  },
};

export const controlLevel: FilterProperty<InventoryResource> = {
  name: 'severity',
  displayName: 'Severity',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const value = conditionValue.toLowerCase();
      return `${capitalize(value)}${value === 'muted' ? '' : '-severity'} control violations`;
    },
  },
};

export const controlId: FilterProperty<InventoryResource> = {
  name: 'controls',
  displayName: 'Control',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.controls.list();

      return body?.controls.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const mutedControlId: FilterProperty<InventoryResource> = {
  name: 'mutedControls',
  displayName: 'Muted control',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.controls.list();

      return body?.controls.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const backupStatus: FilterProperty<InventoryResource> = {
  name: 'backupStatus',
  displayName: 'Backup status',
  type: PropertyType.String,
  group: '',
  linkedProperties: [controlLevel, controlId, mutedControlId],
  column: {
    minSize: 118,
    size: 118,
    enableHiding: false,
    cell: ({ row }) => {
      const status = BackupStatuses[row.original.backupStatus];
      if (!status) {
        return null;
      }
      return (
        <div className='flex items-center'>
          <BackupStatusIcon
            backupStatus={row.original.backupStatus}
            showTooltip
          />
          <ControlsColumnIndicators
            controlViolations={row.original.controlViolations}
          />
        </div>
      );
    },
  },
  filter: {
    Component: BackupStatusFilter,
    shouldHideBadge: (condition) => condition.operator === StringOperator.NotIn,
    Badge: (props) => {
      return BackupStatuses[props.conditionValue]?.title;
    },
  },
};

export const path: FilterProperty<FileSearchRecord> = {
  name: 'path',
  displayName: 'Path',
  type: PropertyType.String,
  group: 'source-location',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) => {
      const delimiter = identifyDelimiter(row.original.path);

      return (
        row.original.path && (
          <Typography>
            {row.getCanExpand() ? (
              <>
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer', marginRight: '5px' },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <i className='bi-dash-square text-[14px] text-primary align-middle' />
                  ) : (
                    <i className='bi-plus-square text-[14px] text-primary align-middle' />
                  )}
                </button>
                {row.original.path.split(delimiter).pop()}
              </>
            ) : (
              <>{row.original.path}</>
            )}
          </Typography>
        )
      );
    },
  },
  field: {
    value: (entity) => entity.path && <Typography>{entity.path}</Typography>,
  },
};

export const modifiedOn: FilterProperty<
  FileSearchRecord | FileExplorerTableItem
> = {
  name: 'modifiedOn',
  displayName: 'Modified on (UTC)',
  type: PropertyType.Date,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (!row.subRows?.length && row.original.snapshots?.length) {
        return (
          <Typography>
            <i className='bi-calendar2-week text-[14px] text-primary align-middle mr-[5px]'></i>
            {dayjs
              .utc(row.original.snapshots[0].modifiedOn)
              .format(DATE_TIME_FORMAT)}
          </Typography>
        );
      }

      return (
        'modifiedOn' in row.original &&
        row.original.modifiedOn && (
          <Typography>
            <i className='bi-calendar2-week text-[14px] text-primary align-middle mr-[5px]'></i>
            {dayjs.utc(row.original.modifiedOn).format(DATE_TIME_FORMAT)}
          </Typography>
        )
      );
    },
  },
};

export const fileType: FilterProperty<FileSearchRecord> = {
  name: 'fileType',
  displayName: 'File type',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (row.original.type === 'd') return null;

      const lastSeg = row.original.path.split('/').pop() || row.original.path;
      const parts = lastSeg.split('.');
      return <Typography>{parts.length > 1 ? parts.pop() : ''}</Typography>;
    },
  },
};

export const fileSize: FilterProperty<
  FileSearchRecord | FileExplorerTableItem
> = {
  name: 'fileSize',
  displayName: 'Size',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (
        'snapshots' in row.original &&
        !row.subRows?.length &&
        row.original.snapshots?.length &&
        row.original.snapshots[0].size
      ) {
        return (
          <Typography>
            {fileSizeFormatter(row.original.snapshots[0].size)}
          </Typography>
        );
      }

      return (
        'size' in row.original &&
        row.original.size && (
          <Typography>{fileSizeFormatter(row.original.size)} </Typography>
        )
      );
    },
  },
};

export const groupName: FilterProperty<SamlGroup> = {
  name: 'groupName',
  displayName: 'Group name',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.groupName && (
        <Typography>{row.original.groupName}</Typography>
      ),
  },
};

export const samlProviderName: FilterProperty<SamlGroup> = {
  name: 'samlProviderName',
  displayName: 'SAML provider',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.saml.providerName && (
        <Typography>{row.original.saml.providerName}</Typography>
      ),
  },
};

export const operationId: FilterProperty<AuditLog> = {
  name: 'operationId',
  displayName: 'Operation ID',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.operationName && (
        <Typography>{row.original.operationName}</Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
};

export const action: FilterProperty<AuditLog> = {
  name: 'action',
  displayName: 'Action',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.action && (
        <Typography className='capitalize'>{row.original.action}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const options = ['create', 'update', 'delete', 'trigger', 'other'].map(
        (id) => ({ id, name: capitalize(id) })
      );

      return <MultiSelectFilter {...props} options={options || []} />;
    },
  },
};

export const requestTime: FilterProperty<AuditLog> = {
  name: 'requestTime',
  displayName: 'Request time (UTC)',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.requestDetails?.requestTime && (
        <Typography>
          {dayjs
            .utc(row.original.requestDetails.requestTime)
            .format(DATE_TIME_FORMAT)}
        </Typography>
      ),
  },
};

export const statusCode: FilterProperty<AuditLog> = {
  name: 'statusCode',
  displayName: 'Status code',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => <Typography>{row.original.statusCode}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by code'} />
    ),
  },
};

export const jobId: FilterProperty<BackupJob | RestoreJob> = {
  name: 'id',
  displayName: 'Job ID',
  type: PropertyType.String,
  group: 'job-details',
  column: {
    cell: ({ row }) =>
      row.original.jobExecutionDetails.id && (
        <Typography>{row.original.jobExecutionDetails.id}</Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
};

export const jobStatus: FilterProperty<BackupJob | RestoreJob> = {
  name: 'status',
  displayName: 'Status',
  type: PropertyType.String,
  group: 'job-details',
  column: {
    enableSorting: false,
    cell: ({ row }) => {
      const status = row.original.jobExecutionDetails.status;

      return (
        status && (
          <Tooltip title={row.original.jobExecutionDetails.errorMessage}>
            <Typography color={JobStatuses[status]?.color}>
              {JobStatuses[status]?.title}
            </Typography>
          </Tooltip>
        )
      );
    },
  },
  filter: {
    Component: (props) => {
      const options = Object.entries(JobStatuses)
        .filter(([key]) => key !== JobStatus.JOB_UNSPECIFIED.toString())
        .map(([key, value]) => ({
          id: key,
          name: value.title,
        }));

      return <MultiSelectFilter {...props} options={options} />;
    },
    Badge({ conditionValue }) {
      return JobStatuses[conditionValue]?.title || conditionValue;
    },
  },
};

export const startTime: FilterProperty<BackupJob | RestoreJob> = {
  name: 'startTime',
  displayName: 'Started at (UTC)',
  type: PropertyType.String,
  group: 'job-details',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.jobExecutionDetails.startTime) && (
        <Typography>
          {dayjs
            .utc(row.original.jobExecutionDetails.startTime)
            .format(DATE_TIME_FORMAT)}
        </Typography>
      ),
  },
};

export const initiatorId: FilterProperty<BackupJob | RestoreJob | AuditLog> = {
  name: 'initiatorId',
  displayName: 'Initiated by',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }: { row: Row<BackupJob | RestoreJob | AuditLog> }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dal = useDAL();
      const { body } = dal.users.list();

      if (isJob(row.original)) {
        const original: BackupJob | RestoreJob = row.original;
        if (!original.jobExecutionDetails.isOnDemand) {
          return 'Scheduled';
        }

        if (!original.jobExecutionDetails.onDemandInitiator) {
          return null;
        }

        return (
          <Typography>
            {body?.users.find(
              (a) => a.id === original.jobExecutionDetails.onDemandInitiator?.id
            )?.givenName ?? original.jobExecutionDetails.onDemandInitiator.name}
          </Typography>
        );
      }

      const initiatorId: string | undefined = row.original.initiatorId;

      if (!initiatorId) {
        return null;
      }
      return (
        <Typography>
          {body?.users.find((a) => a.id === initiatorId)?.givenName ??
            initiatorId}
        </Typography>
      );
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.users.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.users || []).map((a) => ({
            id: a.id,
            name: a.givenName,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.users.list();

      return (
        body?.users.find((x) => x.id === conditionValue)?.givenName || '...'
      );
    },
  },
};

export const targetAccountId: FilterProperty<RestoreJob> = {
  name: 'targetAccountId',
  displayName: 'Restore account',
  type: PropertyType.String,
  group: 'restore-details',
  column: {
    cell: ({ row }: { row: Row<RestoreJob> }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dal = useDAL();
      const { body } = dal.cloudAccounts.restore.list();
      if (!row.original.jobExecutionDetails.onDemandInitiator?.id) {
        return null;
      }
      return (
        <Typography>
          {body?.accounts.find(
            (a) => a.id === row.original.destinationDetails.providerAccountId
          )?.name ?? row.original.destinationDetails.providerAccountId}
        </Typography>
      );
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.restore.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.accounts || []).map((a) => ({
            id: a.id,
            name: a.name,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body, isLoading } = dal.cloudAccounts.restore.list();

      if (isLoading) {
        return '...';
      }

      return (
        body?.accounts.find((x) => x.id === conditionValue)?.name ||
        conditionValue
      );
    },
  },
};

export const targetRegion: FilterProperty<RestoreJob> = {
  name: 'targetRegion',
  displayName: 'Restore region',
  type: PropertyType.String,
  group: 'restore-details',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) =>
      row.original.destinationDetails.region && (
        <Typography>
          {formatRegionName(row.original.destinationDetails.region)}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body: accountsBody } = dal.cloudAccounts.restore.list();
      const { body: regionsBody } = dal.restore.regions.listMany(
        (accountsBody?.accounts || []).map((x) => x.id)
      );

      return (
        <MultiSelectFilter
          {...props}
          options={(regionsBody?.regions || [])
            .map((x) => ({ id: x, name: formatRegionName(x) }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      );
    },
    Badge({ conditionValue }) {
      return formatRegionName(conditionValue) || conditionValue;
    },
  },
};

export const endTime: FilterProperty<BackupJob | RestoreJob> = {
  name: 'endTime',
  displayName: 'Completed at (UTC)',
  type: PropertyType.String,
  group: 'job-details',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.jobExecutionDetails.endTime) && (
        <Typography>
          {dayjs
            .utc(row.original.jobExecutionDetails.endTime)
            .format(DATE_TIME_FORMAT)}
        </Typography>
      ),
  },
};

export const vault: FilterProperty<BackupJob | RestoreJob> = {
  name: 'vaultId',
  displayName: 'Vault',
  type: PropertyType.String,
  group: 'backup-details',
  column: {
    cell: ({ row }) =>
      row.original.jobExecutionDetails.vaultId && (
        <VaultTag vault={row.original.jobExecutionDetails.vaultId} />
      ),
  },
  filter: {
    Component: (props) => {
      const { data } = useBackupVaults();

      return (
        <MultiSelectFilter
          {...props}
          options={(data || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <VaultTag vault={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const { data } = useBackupVaults();

      return data.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const dbEngine: FilterProperty<InventoryResource> = {
  name: 'dbEngine',
  displayName: 'Engine',
  type: PropertyType.String,
  group: 'primary',
  field: {
    value: (entity) =>
      entity.databaseProperties?.engine && (
        <Box className='flex items-center'>
          <CircleImage
            alt={entity.databaseProperties.engine}
            src={ApplicationsLogosWithAliases[entity.databaseProperties.engine]}
            className='mr-[8px]'
          />
          <Typography className='capitalize'>
            {(DatabaseEngineAliases[entity.databaseProperties.engine] ??
              entity.databaseProperties.engine) +
              (entity.databaseProperties.engineVersion
                ? ` (${entity.databaseProperties.engineVersion})`
                : '')}
          </Typography>
        </Box>
      ),
  },
};

export const timestamp: FilterProperty<Notification> = {
  name: 'timestamp',
  displayName: 'Timestamp (UTC)',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.timestamp && (
        <Typography>
          {dayjs.utc(row.original.timestamp).format(DATE_TIME_FORMAT)}
        </Typography>
      ),
  },
};

export const notificationType: FilterProperty<Notification> = {
  name: 'type',
  displayName: 'Type',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.type && (
        <Typography>{NotificationTypes[row.original.type]?.title}</Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <MultiSelectFilter
        {...props}
        options={supportedNotificationTypes.map((id) => ({
          id,
          name: NotificationTypes[id]?.title,
        }))}
      />
    ),
    Badge: ({ conditionValue }) => {
      return NotificationTypes[conditionValue]?.title;
    },
  },
};

export const notificationStatus: FilterProperty<Notification> = {
  name: 'status',
  displayName: 'Status',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.status && (
        <Typography>
          {NotificationStatuses[row.original.status]?.title}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <MultiSelectFilter
        {...props}
        options={Object.values(NotificationStatus)
          .sort((a, b) => a.localeCompare(b))
          .map((id) => ({
            id,
            name: NotificationStatuses[id]?.title,
          }))}
      />
    ),
    Badge: ({ conditionValue }) => {
      return NotificationStatuses[conditionValue]?.title;
    },
  },
};

export const notificationPolicyName: FilterProperty<Notification> = {
  name: 'policyId',
  displayName: 'Policy Name',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.policyName && (
        <Typography>{row.original.policyName}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.notifications.policies.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.notificationPolicies || []).map((a) => ({
            id: a.id,
            name: a.name,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.notifications.policies.list();

      return (
        body?.notificationPolicies.find((x) => x.id === conditionValue)?.name ||
        '...'
      );
    },
  },
};

export const backupPolicy: FilterProperty<BackupJob> = {
  name: 'backupPolicyId',
  displayName: 'Backup policy',
  type: PropertyType.SingleValue,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      row.original.backupPolicyId && (
        <BackupPolicyTagFromId policyId={row.original.backupPolicyId} />
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.backupPolicies || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <BackupPolicyTag policy={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        body?.backupPolicies.find((x) => x.id === conditionValue)?.name || '...'
      );
    },
  },
};
