// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"ed7Z93PvApEgBVyeuAaZL"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import type { ApiException } from '@repo/api-gw-sdk';
import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://025e07d0171e2120007a339fc1c25576@o4507624150466560.ingest.us.sentry.io/4507624151777280',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [Sentry.replayIntegration({})],

    // Filter console*.eon.io
    denyUrls: [/console\d?[.]eon[.]io/],

    beforeSend(event) {
      // Filter out irrelevant errors
      if (
        event.exception?.values?.every((x) => {
          return Number((x as ApiException<unknown>).code) === 404;
        })
      ) {
        return null;
      }
      // Return the event if it's not a 404 error
      return event;
    },
  });
}
